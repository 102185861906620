(function () {
    angular.module('informaApp')
        .service('UserActivityService', UserActivityService);

    function UserActivityService(BaseApiService) {
        return {
            getAll(filter) {
                const query = removeNullProperties(filter);

                return BaseApiService.get('activities', query)
                    .then(response => mapActivities(response.data));
            }
        };
    }

    function mapActivities(activities) {
        return activities.map(activity => {
            return {
                ...activity,
                createdAt: formatCreatedAtDate(activity.createdAt)
            }
        })
    }

    function formatCreatedAtDate(date) {
        return moment(date).utc().format('MM/DD/YYYY, HH:mm A');
    }

    function removeNullProperties(source) {
        const result = {};

        for (let propertyName in source) {
            if (source.hasOwnProperty(propertyName) && source[propertyName] !== null) {
                result[propertyName] = source[propertyName];
            }
        }

        return result;
    }
})();
